<template>
  <list-template
    isDownload
    :search-config="searchConfig"
    @onSearch="onSearch"
    @onReset="onReset"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange"
    @onExportData="handDownload"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { fileDownload, get_school2, get_grade, get_major, as2_search_class } from "@/api";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  mixins: [tableListMixin],
  created() {
    this.getSearchConfig()
  },
  methods: {
    handDownload() {
      fileDownload('/api2/api/educational-routine/art/collect/download', {...this.search}, { name: '艺体生转入收款进度', show: true })
    },
    handleClick(row) {
      this.$router.push('./detail?id=' + row.id)
    },
    getData() {
      this.getDataApi('/api2/api/educational-routine/art/collect/get-list')
    },
    getSearchConfig() {
      get_school2().then(res => {
        this.searchConfig[1].options = res.map(item => ({ label: item.school_name, value: item.id}))
      })
      get_grade().then(res => {
        this.searchConfig[2].options = res.map(item => ({ label: item.grade_name, value: item.id}))
      })
      get_major().then(res => {
        this.list = res
        this.getMajorList()
      })
    },
    getMajorList() {
      const arr = []
      const len = this.list.length

      for (let i = 0; i < len; i++) {
        // 此处只显示艺术生 过滤掉文化生 id = 1
        if(this.list[i].id === 1) continue
        const item = this.list[i].major;
        item.map(child => {
          arr.push({ label: child.name, value: child.id})
        })
      }
      this.searchConfig[5].options = arr
    },
    getClassList(keyword, type, idx) {
      if(!keyword) {
        this.searchConfig[idx].options = []
      } else {
        const { school_id, grade_id } = this.search
        const data = { keyword, type }
        if(school_id) {
          data.school_id = school_id
        }
        if(grade_id) {
          data.grade_id = grade_id
        }
        as2_search_class(data).then(res => {
          this.searchConfig[idx].options = res.map(item => ({ label: item.class_name, value: item.id}))
        })
      }
    },
    onSearchChange(search) {
      this.searchConfig[3].options = []
      this.searchConfig[4].options = []
      if(search.after_class_id) {
        search.after_class_id = ''
      }
      if(search.now_class_id) {
        search.now_class_id = ''
      }
    },
    onReset() {
      this.search = {}
      this.searchConfig[3].options = []
      this.searchConfig[4].options = []
    }
  },
  data() {
    return {
      list: [],
      searchConfig: [
      {
          tag: 'input',
          placeholder: '请输入学生姓名',
          prop: 'keyword'
        },
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          change: (val, search) => {
            this.onSearchChange(search)
          }
        },
        {
          tag: 'select',
          placeholder: '请选择年级',
          prop: 'grade_id',
          options: [],
          change: (val, search) => {
            this.onSearchChange(search)
          }
        },
        {
          tag: 'select',
          placeholder: '请输入转入行政班',
          prop: 'after_class_id',
          reserveKeyword: true,
          filterable: true,
          remote: true,
          remoteMethod: (key) => {
            this.getClassList(key, 0, 3)
          },
          options: []
        },
        {
          tag: 'select',
          placeholder: '请输入当前行政班',
          prop: 'now_class_id',
          reserveKeyword: true,
          filterable: true,
          remote: true,
          remoteMethod: (key) => {
            this.getClassList(key, 1, 4)
          },
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择专业方向',
          prop: 'major',
          options: []
        },
      ],
      tableConfig: [
        {
          prop: 'student_name',
          label: '学生姓名',
        },
        {
          prop: 'student_no',
          label: '学号',
        },
        {
          prop: 'school',
          label: '校区',
        },
        {
          prop: 'grade',
          label: '年级',
        },
        {
          prop: 'class',
          label: '转入行政班',
        },
        {
          prop: 'now_class',
          label: '当前行政班',
        },
        {
          prop: 'major',
          label: '转入专业方向',
        },
        {
          prop: 'major_teacher',
          label: '对应专业老师',
        },
        {
          prop: 'approve_time',
          label: '转入审批通过时间',
        },
        {
          prop: 'outstanding_amount',
          label: '欠缴总金额',
        },
        {
          label: '操作',
          width: '100rem',
          handle: true,
          fixed: 'right',
          buttons: [{ type:"view", text:"查看" }]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
